import React, { useEffect, useState } from 'react';
import SeoMetaTags from '../Seo';

import { Link } from 'react-router-dom'
import CourseLayout from '../Layouts/CourseLayout';
import image from '../assets/images/courses/veri-bilimi-101.png'
import imageII from '../assets/images/courses/veri-bilimi-101-2.png'
import content from '../Courses/veribilimi101.json'

const VeriBilimi101 = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // JSON dosyasını kullanarak veriyi set edin
    setData(content);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }


  return(
    <CourseLayout>
      <div>
      <SeoMetaTags
        title="Veri Bilimi 101"
        description="SEOEM Akademi ile veri bilimi ve makine öğreniminin heyecanlı dünyasına giriş yapın!"
        url="https://academy.seoem.co/veribilimi101"
        image="../assets/images/courses/veri-bilimi-101.jpg"
      />
      <h1 className='text-center text-seoem-100 text-3xl font-bold my-14'>{data.title} <span className='text-sm font-thin'></span></h1>
      <div className='flex max-xl:flex-col max-xl:items-center w-2/3 justify-center mx-auto my-4'>

        <div className='relative max-lg:mb-10'>
          <img className='max-w-[450px] max-lg:w-full rounded-md' src={image} alt='frontend101 görsel'/>
          <span className='bg-red-700 text-white text-center p-1 rounded-md absolute top-2 left-2'>{data.datewtext}</span>
        </div>
        <div className='flex flex-col text-justify max-xl:items-center mx-4 lg:ml-10'>

          <p className='text-black text-lg font-thin opacity-75'>{data.description}</p>
          <p className='text-black text-lg font-thin opacity-75' dangerouslySetInnerHTML={{__html:data.price}}></p>
          <h2 className='text-left text-seoem-100 text-2xl font-bold mt-4'>Kazanımlar</h2>
          <ul className='text-black text-lg font-thin opacity-75 list-disc pl-2 mt-4'>
            {data.awards.map((award, index) => (
            <li key={index}>{award}</li>
            ))} 
          </ul>
          
        </div>
        
      </div>
        <ul className='mt-4 lg:ml-10 lg:flex lg:justify-center text-center'>
          <Link target='_blank' className='btn btn-light btn-enroll' to={data.link}>Ön Kayıt Yap</Link>
        </ul>
      <div className='image-content-box'>
        <img src={imageII} alt="Eğitim içeriği" className='image-content'/>
      </div>
    </div>
    </CourseLayout>
  )
}

export default VeriBilimi101;